import { Directive, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@app/core/services/api/user.service';

@Directive({
  selector: '[appPermissions]'
})
/**
 * Directive to show/remove view based on given permissions string and users permissions.
 */
export class PermissionsDirective {
  constructor(
    private viewTemplate: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private us: UserService
  ) {}

  /**
   * Dot notation user permissions. String need to be in format `category.selector` or
   * `['sameCategory.selector', 'sameCategory.selector']`, that is used
   * to get permissions from {@link UserService.categoryFeaturePermission$}.
   */
  @Input()
  public set appPermissions(permissions: string | string[]) {
    this.evaluatePermissions(permissions);
  }

  private evaluatePermissions(permissions: string | string[]) {
    const permission$ = (category, selector) => this.us.categoryFeaturePermission$(category, selector);
    if (permissions instanceof Array) {
      const category = permissions[0].split('.')[0];
      const selector: string[] = permissions.map((permission) => permission.split('.')[1]);
      permission$(category, selector).subscribe((permission) => {
        this.resolve(permission);
      });
    } else {
      const [category, selector] = permissions?.split('.') ?? '';
      permission$(category, selector).subscribe((permission) => {
        this.resolve(permission);
      });
    }
  }

  private resolve(permission: boolean) {
    this.viewContainer.clear();
    if (permission === true || permission === null) {
      this.viewContainer.createEmbeddedView(this.viewTemplate);
    }
  }
}

@NgModule({
  imports: [],
  exports: [PermissionsDirective],
  declarations: [PermissionsDirective],
  providers: []
})
export class PermissionsDirectiveModule {}
